import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'


@Component({
    components: {},
})
export default class SurveyThumbnail extends Vue {

    @Prop({ type: Object }) value!: survey.SurveyThumbnailRequest

    url: string = ""
    isShow : boolean = false
    created() {
       if(this.value.url){
           this.createThumbnailUrl()
       }
    }

    @Watch("value.Width")
    @Watch("value.Height")
    @Watch("value.url")
    onChangedValue(){
         
        this.createThumbnailUrl()
    }

    createThumbnailUrl(){
        const hostValue = this.getOrginInUrl()
        this.url = `${hostValue}/CommonSurvey/thumbnail/api?Width=${this.value.Width}&Height=${this.value.Height}&ImgUrl=${encodeURIComponent(this.value.url)}`
    }

    onError(val : any){
        
        if(val){
            const target = val.currentTarget
            if(process.env.NODE_ENV === 'production'){
                target.src = `/cm/gres/images/img_not_found.jpg`

            }else{
                target.src = `/img_not_found.jpg`
            }
        }
    }

    onLoad(){
         
        this.isShow = true
        
    }

    getOrginInUrl(){
        
        if(process.env.NODE_ENV === 'production'){
            const currentUrl = new URL(window.location.href)
            return `${currentUrl.origin}/cm`
        }else{ //개발
            return `http://localhost:5000`
        }

    }
}
