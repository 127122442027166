import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'


@Component({
  components: {  },
})

export default class SurveySubHeader extends Vue {
  @Prop({ type: String }) value!: string 
  @Prop({ type: Boolean }) enableIcon!: Boolean 

  toggleFavorite : boolean = true
  toggleAlert : boolean = true

  created() {
    this.initToggle()
    
  }

  initToggle(){
    
    this.toggleFavorite = true
    this.toggleAlert = true
  }

  onClickAlert(){

    this.toggleAlert = !this.toggleAlert
  }

  onClickFavorite(){
    this.toggleFavorite = !this.toggleFavorite
  }
}
