import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'


@Component({
  components: {  },
})

export default class SurveyWriteHeader extends Vue {
  @Prop({ type: Number }) value!: number 
 
}
