import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'


@Component({
    components: {},
})

export default class SurveySearchFilter extends Vue {
    @Prop({ type: Boolean }) isAdmin!: boolean  

    selectedStatus: number = 0
    selectedFilter: number = 0
    keyword: string = ""
    surveyStatus: Array<any> = [
        {
            value: 0,
            text: this.$t('전체').toString()
        },
        {
            value: 1,
            text: this.$t('대기중').toString()
        },
        {
            value: 2,
            text: this.$t('진행중').toString()
        },
        {
            value: 3,
            text: this.$t('완료').toString()
        }
    ]

    filter: Array<any> = [
        {
            value: 0,
            text: this.$t('제목').toString()
        },
        {
            value: 1,
            text: this.$t('설명').toString()
        },
        {
            value: 2,
            text: this.$t('작성자').toString()
        }
    ]


    onSearch() {
        //alert('search!')
        this.$emit('search', {
            keyword: this.keyword,
            filter: this.selectedFilter,
            surveyStatus: this.selectedStatus
        })
    }

    filteredStatus() {
        //관리자면 게시대기 필터 제공
        return this.surveyStatus.filter(item=>{
            return item.value != (this.isAdmin ? 999 : 1)
        })
    }
}
