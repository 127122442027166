import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import * as dateUtil from '@appbase/util/date-helper'



@Component({
    components: {},
})

export default class SurveyAttachmentList extends Vue {
    @Prop({ type: Array }) value!: Array<survey.SurveyAttachmentInfo>



    // created() {


    // }


    // convertDate(date: string) {
    //     return dateUtil.uGetDateFormat_UTC("yyyy.MM.dd", new Date(date))
    // }
    async downloadAll() {
        await (window as any).alertAsync(this.$t('전체다운로드').toString())
    }
}
