import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapActions,mapMutations } from 'vuex';
import * as types from '@survey/store/types'
import { SurveyExampleForm } from '@survey/components'

export interface ExampleOrderObj {
    exampleId: number,
    currentOrders: number
}


@Component({
    components: {
        SurveyExampleForm

    },
    methods: {
        ...mapActions({
            //setSurveyId: types.SURVEY_CREATED_SURVEYID
            update: types.SURVEY_QUESTION,
            delete: types.SURVEY_DELETE_QUESTION,
            createExample: types.SURVEY_EXAMPLE,
            deleteExample: types.SURVEY_DELETE_EXAMPLE,
            copy: types.SURVEY_COPY_QUESTION
        }),
        ...mapMutations({
            resorting : types.SURVEY_RESORTING_EXAMPLE
        })
    },
    computed: {
        ...mapGetters({
            // createdSurveyId: types.SURVEY_CREATED_SURVEYID
        }),
    }
})



export default class SurveyQuestionForm extends Vue {
    @Prop({ type: Object }) question!: survey.SurveyQuestionDetail
    @Prop({ type: Number }) lastQuestionOrder!: number
    
    delete!: (surveyQuestionId: number) => void
    update!: (data: survey.SurveyQuestionRequest) => void
    copy!: (data: survey.SurveyQuestionDetail) => Promise<void>
    createExample!: (data: survey.SurveyExampleRequest) => Promise<void>
    deleteExample!: (surveyExampleId: number) => void
    resorting!: (params: number) => void

    lastExampleOrder: number = 1
    processingCopyTemplate : boolean = false
    processingAddExample : boolean = false

    created() {
        this.initExampleOrder()   
    }

    //질문유형에 대한 설명
    questionTypes: Array<any> = [
        {
            value : 0,
            text : this.$t('선택형').toString(),
            description : this.$t('선택형_설명').toString()

        },
        {
            value : 1,
            text : this.$t('선택형_복수선택').toString(),
            description : this.$t('선택형_복수선택_설명').toString()

        },
        {
            value : 2,
            text : this.$t('서술형').toString(),
            description : this.$t('서술형_설명').toString()

        }
    ]
   

    @Watch("question.examples")
    initExampleOrder() {
         
        let maxOrders: number = 1

        maxOrders = Math.max(...this.question.examples.map(item => {
            return item.exampleOrders
        }, 0))


        if (!Number.isFinite(maxOrders)) {
            maxOrders = 0
        }

        this.lastExampleOrder = maxOrders + 1

    }

    async addExample() {
        if(this.processingAddExample){
            await (window as any).alertAsync(this.$t('보기_추가_중').toString())
            
            return
        }

        this.processingAddExample = true

        this.question.examples

        this.createExample(
            {
                exampleInfo: {
                    surveyId: this.question.questionInfo.surveyId,
                    surveyQuestionId: this.question.questionInfo.surveyQuestionId as number,
                    exampleText: "",
                    exampleOrders: this.lastExampleOrder,
                    fileCode: "",
                }
            }).then(() =>{
                //debugger
                //this.lastExampleOrder++
                this.processingAddExample = false
            })
    }


    onChangedQuestion() {

        this.update(this.question as survey.SurveyQuestionRequest)
    }

    onChangedQuestionType() {
        //타입이 변경되면 질문 하위 보기 모두 삭제
        this.question.examples.forEach(item => {
            this.deleteExample(item.surveyExampleId as number)
        })

        //그리고 업데이트
        this.onChangedQuestion()
    }

    updateOrders(orderType: string) { // up , down
        // alert(`changed orders : ${orderType}`)

        var orders = this.question.questionInfo.questionOrders
        var questionId = this.question.questionInfo.surveyQuestionId as number

        this.$emit("change", {
            questionId: questionId,
            currentOrders: orders
        }, orderType == "up" ? true : false)
    }



    deleteQuestion() {

        var orders = this.question.questionInfo.questionOrders
        var questionId =<number>this.question.questionInfo.surveyQuestionId  

       
        this.delete(questionId)

        //여기에서 foreach 로 삭제 대상 위로 up 다하면 될듯
        //orderAdjustment 필요
        this.$emit('delete',{
            questionId: questionId,
            currentOrders: orders
        })

    }

    async copyTemplate() {
        //중복 작업을 방지하기 위해 완료 이전에 함수 호출 불가
        if(this.processingCopyTemplate) {
            await (window as any).alertAsync(this.$t('템플릿_복사_중').toString())
            return
        }

        this.processingCopyTemplate = true

        let data = {
            questionInfo: {
                surveyId: this.question.questionInfo.surveyId,
                questionOrders: this.lastQuestionOrder,
                questionType: this.question.questionInfo.questionType,
                isAdditionalOption: this.question.questionInfo.isAdditionalOption,
                questionText: this.question.questionInfo.questionText
            },
            examples: this.question.examples.map(item => {
                return {
                    ...item,
                    surveyExampleId: undefined
                }
            })
        } as survey.SurveyQuestionDetail


        this.copy(data).then(() => {
            this.$emit('focus')
            this.processingCopyTemplate = false
            
        })

        //포커스 마지막 질문으로 
    }

    setQuestionTypeDescription(){
        let selectedQuestionType : number = this.question.questionInfo.questionType

        let selectedObj = this.questionTypes.filter(item =>{
            return item.value == selectedQuestionType
        })

        return selectedObj[0].description
    }

    changeExampleOrders(targetObj:ExampleOrderObj, isUp:boolean){
      
 
        if(targetObj.currentOrders == 1 && isUp || 
            (targetObj.currentOrders == this.lastExampleOrder-1 && !isUp)){
            this.processingAddExample = false
            return 
         }
 
         //분기 올리냐 내리냐
         //if(isUp){
          //올리는건 order 가 -1
         //}else{
          // 내리는건 order +1
             //변경 대상이 있는 경우 체크해서 변경해야됨
             // 1순위에서 2순위로 변경되면 기존 2순위를 1순위로 변경 서로 swap
             let existsExample = this.question.examples.filter(item=>{
                 return item.exampleOrders == targetObj.currentOrders + (isUp ? -1 : 1)
             })
 
             //변경 대상이 있기 때문에 먼저 변경
             if(existsExample.length > 0){
                 existsExample[0].exampleOrders  = existsExample[0].exampleOrders + (isUp ? 1 : -1)
                this.createExample({ exampleInfo : existsExample[0]})
             }
 
             let example = this.question.examples.filter(item=>{
                 return item.surveyExampleId == targetObj.exampleId
             })
 
             if(example.length > 0){
                example[0].exampleOrders = example[0].exampleOrders + (isUp ? -1 : 1)
                this.createExample({ exampleInfo : example[0]}) 
             }
  
             this.resorting(this.question.questionInfo.surveyQuestionId as number)
            
 
    }

    
    orderAdjustment(targetObj : ExampleOrderObj){
        //alert(questionId)

        
        this.question.examples.forEach(exam => {
            if(<number>exam.exampleOrders > targetObj.currentOrders){
                //alert(question.questionInfo.questionOrders)

                this.changeExampleOrders({
                    exampleId :<number>exam.surveyExampleId,
                    currentOrders : exam.exampleOrders
                },true)
            }
        })
    }
 
    deleteExampleImg(exampleId : number){
         //하위  컴포넌트 호출
         let refValue = `exampleForm${exampleId}`
         var component = (this.$refs as any)[refValue]
 
         component[0].deleteImg()

         //순서초기화
         //this.initExampleOrder()
    }


    previewImg(data:any){
        this.$emit('modal',{
            ...data,
            questionId : <number>this.question.questionInfo.surveyQuestionId
        })
    }
}
