import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapMutations } from 'vuex'
import * as types from '@survey/store/types'
import api from '@survey/api'
import SurveyThumbnail from '@survey/components/Common/SurveyThumbnail.vue'

@Component({
    components: {
        SurveyThumbnail
    },
    methods: {

        ...mapMutations({
            deleteAnswer: types.SURVEY_DELETE_ANSWER
        })
    },

})

export default class SurveyReadExamples extends Vue {
    @Prop({ type: Number }) questionId!: number
    @Prop({ type: Array }) value!: Array<survey.SurveyExampleItem>
    @Prop({ type: Number }) questionType!: number
    @Prop({ type: Boolean }) isAdditionalOption!: boolean
    @Prop({ type: Array }) answers!: Array<survey.SurveyAnswerItem>
    @Prop({ type: Boolean }) isCompleted!: boolean
    @Prop({ type: Number }) surveyStatus!: number
    @Prop({ type: Boolean }) isStatisticsMode!: boolean
  

    deleteAnswer!: (payload:any) => void
    isInit: boolean = true

    imgInfo: Array<any> = []
    tempSrc: string = ""
    textAreaValue : string = ""
    
     

    created() {
        //const lastItem = this.value[this.value.length - 1]
        this.value.forEach(item => {
            if (item.fileCode != "") {
                api.survey.getSurveyAttachmentList(item.fileCode).then(result => {
                  
                    this.imgInfo.push({
                        size : result[0].size,
                        exampleId: item.surveyExampleId,
                        downloadUrl: result[0].downloadUrl
                    })
               
                })
            } 
        })

        //서술형은 created 에서 초기화
        if(this.isCompleted && this.questionType ==2) this.setAnswer()
    }

    @Watch("isStatisticsMode")
    onChangedStatisticsMode(){
        if(!this.isStatisticsMode) {
             
            this.isInit = true
        }
    }
 
    @Watch("answers")
    setAnswer(exampleId?: number) {
        //debugger
        //응답 수정이 아닌 경우
        if (!this.isCompleted) {
            if (this.isAdditionalOption || this.questionType == 2) {
                return ""
            } else {
                return false
            }
        } else {
            //초기화
            
            
            //주관식인 경우 v-model (글자수 체크)
            
            if(this.questionType == 2){ 
                this.textAreaValue = this.answers[0].surveyAnswer.toString()
            }
            //기타옵션, 복수인경우 첫번째 답변만 체크하기 때문에 발동 안됨
            // else if (this.answers[0]?.isAdditionalAnswer ) {
            //     if(this.questionType == 1) //복수선택인경우
            //     {

            //     }
            //     else{ //복수선택이 아닌 경우
            //         return this.answers[0].surveyAnswer
            //     }
               

            // }
            else if (this.questionType == 0 || this.questionType == 1) { // 객관식 케이스
                //debugger
                //체크박스 or 라디오 value 임
                //return true, false 
 
                var filtered = this.answers.filter(item => {
                    return parseInt(item.surveyAnswer) == exampleId
                })

                //추가답변은 따로 처리
                //추가답변인경우는 exampleId 가 없음
                 if(!exampleId){ 
                    const additionalAnswer = this.answers.filter(item =>{
                        return item.isAdditionalAnswer == true 
                    })
 
                    if(additionalAnswer.length > 0 && (this.isInit || this.isStatisticsMode)){
                        this.isInit = false
                        this.textAreaValue = additionalAnswer[0].surveyAnswer
                        return true
                    }else if(additionalAnswer.length > 0){
                         
                        return true
                    }
                    else{ //선택 안된 경우
                        this.textAreaValue =""
                        return false
                    }
                }
               


                //     // case01 : 기타가 선택된 상태에서 input 텍스트 변경 시
                //     // additionalanswer.length 는 1일 거고

                //     // case02 : 최초 로드 시
                //     //additionalswer.length 1  








                //     //수정 시 기타 답변이 변경 안되는 케이스 발생
                //     //수정시에는 이미 textAreaValue 가 있으니 hitting 조건으로 추가
                //     //사용자 통계 볼 때 또 안바뀜...
                //     //사용자 통계 showSearch flag 전달해서 같이 체크
                //     // if(additionalAnswer.length > 0 ){
                //     //     this.textAreaValue = additionalAnswer[0].surveyAnswer
                //     //     return true
                //     // }
                //     // else if(this.textAreaValue != "" && !this.isStatisticsMode){
                //     //      return 
                //     // }
                //     // else{
                //     //     this.textAreaValue = ""
                //     //     return false
                //     // }
                // }


                if (filtered.length > 0) {
                    return true
                } 
                else {
                    return false
                }
            }
        }
    }

   


    onChanged(evt: any, isAdditionnal: boolean) {
 
        let answers = evt.currentTarget.value
        
        //주관식, 객관식 구분
        let isMultipleChoice = true
        if (this.questionType == 2 || isAdditionnal) { //주관식, 추가 답변인경우
            isMultipleChoice = false
            
           

            //글자수 제한 
            //txtArea 컨트롤에 maxlength 는 설정 되어있어서 필요는 없을 듯?
            // const txtCount = (answers as string).length
            // this.txtAreaLength = txtCount
            // if( txtCount > 2000){
            //     alert('최대 2,000자 까지 입력 가능합니다.')
            //     return false
            // }
           
        }
        //기타 응답 체크박스 체크되는 경우
        if(isAdditionnal){
            var isCheckBox = evt.currentTarget.checked
            if(isCheckBox) answers = ""
        }

        //복수 응답 구분
        //현재는 객관식(복수선택) type 2 외엔 없음
        let isMultipleAnswer = this.questionType == 1 ? true : false
 

        let data: survey.SurveyAnswerItem = {
            surveyId: 0,
            surveyQuestionId: this.questionId,
            surveyAnswer: answers,
            isAdditionalAnswer: isAdditionnal,
            isMultipleChoice: isMultipleChoice,
            isMultipleAnswer: isMultipleAnswer
        }

        //debugger
        //다중 선택인경우 체크해제될 때는 이벤트에서 제외
        if (!evt.currentTarget.checked && evt.currentTarget.type == 'checkbox') {
            this.deleteAnswer({answers : answers, questionId : this.questionId})
        } else {
            this.$emit('update', data)
        }
    }

    getDownloadUrl(exampleId: string) {
        if(this.imgInfo.length == 0) return
 
        var target = this.imgInfo.filter(item => {
            return item.exampleId == exampleId
        })
 
        // if(target[0].size > 1048576){
        //     //1MB 이상일 때 원본 표시 안함
        //     return `http://localhost:5000/CommonSurvey/thumbnail/api?Width=350&Height=200&ImgUrl=${encodeURIComponent(target[0]?.downloadUrl)}`
        // }

        return target[0]?.downloadUrl
    }

   
    
    // 미사용
    getThumbnailValue(exampleId: string) {


        var target = this.imgInfo.filter(item => {
            return item.exampleId == exampleId
        })


        return {
            url: target[0]?.downloadUrl,
            Width: 300,
            Height: 300
        }
    }


     
   
}
