import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters, mapActions } from 'vuex';
import * as types from '@survey/store/types'
import SurveyTargetContainer from '@survey/components/SurveyWriteS1/SurveyTargetContainer.vue'
import SurveyThumbnail from '@survey/components/Common/SurveyThumbnail.vue'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import FileUploder from '@appbase/components/common/FlowFileUploader/FlowFileUploader.vue'
import api from '@survey/api'


 

@Component({
    components: {
        SurveyThumbnail,
        DatePicker,
        SurveyTargetContainer,
        FileUploder
    },
    methods: {
        ...mapActions({
            setSurveyId: types.SURVEY_CREATED_SURVEYID,
            delete: types.SURVEY_DELETE_EXAMPLE,
            update: types.SURVEY_EXAMPLE
        })
    },
    computed: {
        ...mapGetters({
            createdSurveyId: types.SURVEY_CREATED_SURVEYID
        }),
    }
})

export default class SurveyExampleForm extends Vue {
    @Prop({ type: Object }) example!: survey.SurveyExampleItem
    delete!: (surveyExampleId: number) => void
    update!: (data: survey.SurveyExampleRequest) => void

    attachment: survey.SurveyAttachmentInfo = Object.create(null)

    created() {
        this.initAttachmentInfo()
    }

    @Watch("example.fileCode")
    onChagnedFileCode() {
        this.initAttachmentInfo()
    }

    //fileCode 생성
    generateAttachmentCode() {
        const tag = 'SURVEY_EXAMPLE'
        const uid = `UID${Date.now()}`
        const code = `${tag}_${uid}`
        return code
    }

    onChanged() {

        this.update({
            exampleInfo: this.example
        })
    }

    updateOrders(orderType: string) {

        var orders = this.example.exampleOrders
        var exampleId = this.example.surveyExampleId as number

        this.$emit("change", {
            exampleId: exampleId,
            currentOrders: orders
        }, orderType == "up" ? true : false)
    }

    deleteExample() {
        var orders = this.example.exampleOrders
        var exampleId = <number>this.example.surveyExampleId

        this.delete(exampleId)

        this.$emit('delete', {
            exampleId: exampleId,
            currentOrders: orders
        })


    }

    addImage() {
        // alert('이미지추가')

        let fileDialog = <HTMLElement>document.querySelector(`#fileDialog${this.example.surveyExampleId}`)
        fileDialog.click()
    }

    onSelectImgFiles(evt: Event) {
         
        if (evt.target) {
            const files = (evt.target as HTMLInputElement).files

            if (files) {
                //실제 수행
                //단일 파일임
                let file = files[0]
                const now = new Date()

                 

                api.survey.uploadSurveyExampleFile({
                    File: files[0],
                    Code: this.generateAttachmentCode(),
                    CodeValues: "SURVEY,EXAMPLE",
                    FlowIdentifier: `${now.getTime()}+'-'+${file.size}-${file.name.replace(/[^0-9a-zA-Z_-]/gim, '')}`,
                    FlowFilename: file.name,
                    FlowRelativePath: file.name
                }).then(async result => {
                    await (window as any).alertAsync(this.$t('이미지_업로드_완료').toString())
                    //우선 example filecode 업데이트되어야함
                    let fileCode = result.code
 
                    this.update({
                        exampleInfo: {
                            ...this.example,
                            fileCode: fileCode
                        }
                    })

                    //파일정보(다운로드링크) 조회는 watch에서 해야될듯
                })


            }
        }
    }


    initAttachmentInfo() {

        let fileCode = this.example.fileCode
        if (fileCode != "") {
            api.survey.getSurveyAttachmentList(fileCode).then(result => {
                if (result.length > 0) {
                    this.attachment = {
                        fileName: result[0].name,
                        downloadUrl: result[0].downloadUrl
                       
                    }
                }
            })
        } else {
            this.attachment = Object.create(null)
        }
    }


    deleteImg() {
        this.update(
            {
                exampleInfo: {
                    ...this.example,
                    fileCode: ""
                }
            })
        //html input[file] 초기화 해줘야 됨, 삭제 후 다시 업로드 시  @change 이벤트 hitting
        const inputEl = <HTMLInputElement>document.querySelector(`#fileDialog${this.example.surveyExampleId}`)
        inputEl.value = ""
    }

    previewImg() {

        this.$emit('modal', {
            ...this.attachment,
            exampleId : <number>this.example.surveyExampleId
        } )
    }

    getThumbnailValue() {


        var target = this.attachment 


        return {
            url: target.downloadUrl,
            Width: 100,
            Height: 80
        }
    }
}
