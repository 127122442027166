import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { mapGetters, mapActions } from 'vuex';
import * as types from '@survey/store/types'
import * as dateUtil from '@appbase/util/date-helper'
import SurveyTargetContainer from '@survey/components/SurveyWriteS1/SurveyTargetContainer.vue'
import DatePicker from '@appbase/components/DatePicker/DatePicker.vue'
import FileUploder from '@appbase/components/common/FlowFileUploader/FlowFileUploader.vue'
import { invalidateUrl } from '@appbase/util/invalidate-url'
import { FlowFileMeta } from '@appbase/components/common/FlowFileUploader/flow-file-meta'
import api from '@survey/api'

import appBaseApi from '@appbase/api'
import flowHelper from '@appbase/components/common/FlowFileUploader/flow-helper';


@Component({
    components: {
        DatePicker,
        SurveyTargetContainer,
        FileUploder

    },
    methods: {
        ...mapActions({
            setSurveyId: types.SURVEY_CREATED_SURVEYID
        })
    },
    computed: {
        ...mapGetters({
            createdSurveyId: types.SURVEY_CREATED_SURVEYID
        }),
    }
})

export default class SurveyWriteS1Grid extends Vue {

    setSurveyId!: (surveyId: number) => void
    createdSurveyId!: number

    surveyRequest: survey.SurveyRequest = Object.create(null)

    // title : string = ""
    // isTargetAll : number = 0
    // targets : Array<any> = []
    // isAnonymous : boolean = false
    // isPublic : boolean = false

    //바로게시 옵션
    nowPosting: boolean = false
    showNowPostingBtn: boolean = true

    //데이트피커 시작, 종료
    startDate: string = ""
    endDate: string = ""

    //fileUploder
    codeValues : Array<string> = ["SURVEY","MAIN"] // 파일코드는 고정임
    fileMetaInfo: FlowFileMeta = Object.create(null)
    files: any[] = []
    uploadUrl: string = ""
    deleteUrl: string = ""

    //show target container
    showContainer: boolean = false
    containerTargets: Array<any> = []

    isReady: boolean = false

    //fileSelectAll toggle
    // selectAllToggle : boolean = false

    async created() {

        //surveyRequest 초기 데이터 
        await this.initData()
        //시작, 종료일 초기화
        this.initDate()
        //upload 초기화
        this.initFileUploader()

        this.isReady = true
    }

    async initData() {
        let querySurveyId = parseInt(this.$route.query.surveyId as string)
        let isEmptySurveyID = typeof (this.createdSurveyId)


        //신규 생성 case
        if (isEmptySurveyID == 'object' && isNaN(querySurveyId)) {
            this.surveyRequest.mainInfo = {
                title: "",
                description: "",
                isTargetAll: true,
                isAnonymous: false,
                isPublic: false,
                status: 1 // 최초 상태 대기,

            } as survey.SurveyMainInfo
        } else {//생성된 설문이 있는 경우
            let surveyId = isEmptySurveyID == 'object' ? querySurveyId : this.createdSurveyId

            await api.survey.getSurveyById(surveyId).then(async result => {

                if (result.mainInfo != null) {
                    //store 에 surveyId 저장
                    this.setSurveyId(result.mainInfo.surveyId as number)

                    //데이터 초기화 진행
                    //MainInfo
                    let mainInfo = result.mainInfo

                    this.surveyRequest.mainInfo = {
                        surveyId: mainInfo.surveyId,
                        title: mainInfo.title,
                        description: mainInfo.description,
                        isTargetAll: mainInfo.isTargetAll,
                        isAnonymous: mainInfo.isAnonymous,
                        isPublic: mainInfo.isPublic,
                        status: mainInfo.status, // 최초 상태 대기,
                        fileCode: mainInfo.fileCode,
                        startDate: mainInfo.startDate,
                        endDate: mainInfo.endDate
                    } as survey.SurveyMainInfo

                    //바로게시 버튼은 현재 상태가 진행중이면 표시하지 않는다
                    if (mainInfo.status == 2) {
                        this.showNowPostingBtn = false
                    }

                    //targets
                    //컨테이너 정보
                    this.surveyRequest.targets = result.targets
                    this.containerTargets = (result.targets as Array<any>).map(item => {

                        return {
                            identityCode: item.identityCode,
                            id: item.identityCode,
                            personCode: item.identityCode,
                            name: item.displayName,
                            deptName: item.deptName || '',
                            isDepartment: item.isDepartment

                        }
                    })

                    //여기서 파일 정보 초기화
                    //파일코드가 있는 경우 목록 초기화
                    let fileCode = result.mainInfo.fileCode
                    if (fileCode != "") {
                        appBaseApi.file.getFiles(fileCode).then(result => {
                            
                            this.files = result.map(item => flowHelper.convertToFlowFile({
                                    ...item,
                                    uniqueIdentifier: item.id,
                                    type: 'Database',
                                }))
                        })
                    }

                    this.onChangedSurveyRequest()

                } else { // maininfo 가 null 인 경우는 없는 정보 조회 잘못된 접금
                    //리스트 화면으로 redirect
                    await (window as any).alertAsync(this.$t('잘못된_접근입니다').toString())
                    this.$router.push({
                        name: "SurveyList"
                    })
                }
            })

        }

    }


    initFileUploader() {
        this.uploadUrl = invalidateUrl('/cm/file/store/flow')
        this.deleteUrl = invalidateUrl('/cm/file/store/delete')

        let fileCode = this.surveyRequest.mainInfo.fileCode

        this.fileMetaInfo = {
            code: fileCode || this.generateAttachmentCode(),
            codeValues: this.codeValues,
            type: 'file',
        }



        this.surveyRequest.mainInfo.fileCode = this.fileMetaInfo.code


    }

    initDate() {

        if (this.surveyRequest.mainInfo.startDate == null) {
            let now = new Date()
            let endDate = new Date()
            new Date(endDate.setDate(endDate.getDate() + 7))

            this.startDate = dateUtil.uGetDateFormat('yyyy-MM-DD', now)
            this.endDate = dateUtil.uGetDateFormat('yyyy-MM-DD', endDate)

            //surveyMainInfo
            this.surveyRequest.mainInfo.startDate = now
            this.surveyRequest.mainInfo.endDate = endDate
        } else {

            this.startDate = dateUtil.uGetDateFormat('yyyy-MM-DD', new Date(this.surveyRequest.mainInfo.startDate))
            this.endDate = dateUtil.uGetDateFormat('yyyy-MM-DD', new Date(this.surveyRequest.mainInfo.endDate))
        }

    }

    updateStartDate(date: Date) {
        // console.log(`updateType : ${updateType}`)
        this.startDate = dateUtil.uGetDateFormat('yyyy-MM-DD', date)
        this.surveyRequest.mainInfo.startDate = date
        this.onChangedSurveyRequest()
    }

    updateEndDate(date: Date) {

        this.endDate = dateUtil.uGetDateFormat('yyyy-MM-DD', date)
        this.surveyRequest.mainInfo.endDate = date
        this.onChangedSurveyRequest()
    }


    updateTargetUsers(users: Array<survey.SurveyTargets>) {
        this.containerTargets = users
        this.surveyRequest.targets = users
        this.onChangedSurveyRequest()
    }

    deleteTargetUser(personCode: string) {
        let filteredData = this.containerTargets.filter(item => {
            return item.personCode != personCode
        })
        this.surveyRequest.targets = filteredData
        this.containerTargets = filteredData
        this.onChangedSurveyRequest()
    }

    deleteAttachment(fileIdentifier : string){
        
        const codeValues = this.codeValues
        const code = <string>this.surveyRequest.mainInfo.fileCode
        
        appBaseApi.file.deleteFiles(code, codeValues, [""], [fileIdentifier]).then(() => {
            this.files = this.files.filter(file => {
                return file.serverPath  != fileIdentifier
            })
        })

    }

    //fileCode 생성
    generateAttachmentCode() {

        const tag = 'SURVEY_MAIN'
        const uid = `UID${Date.now()}`
        const code = `${tag}_${uid}`
        console.log('generateAttachmentCode', code)
        return code
    }

    //update surveyRequest Info
    // @Watch("surveyRequest.mainInfo.title")
    onChangedSurveyRequest() {
        const isTargetAll = this.surveyRequest.mainInfo.isTargetAll
        this.showContainer = !isTargetAll

        let isStatusEdit = false
        //바로게시 옵션 적용 신규생성일 때 만 X
        if (!this.surveyRequest.mainInfo.surveyId) {
            isStatusEdit = true
        }
        else if (this.surveyRequest.mainInfo.surveyId && this.surveyRequest.mainInfo.status == 1) {
            isStatusEdit = true
            //들어오는 조건
        }

        if (isStatusEdit) {
            this.surveyRequest.mainInfo.status = this.nowPosting ? 2 : 1
        }

        this.$emit("update", this.surveyRequest)
 
    }


    // selectAllFiles(evt : any ){
         
    //     this.files = this.files.map(item =>{
    //         return {
    //             ...item,
    //             selected : this.selectAllToggle ? false : true
    //         }
    //     })

    //     this.selectAllToggle = !this.selectAllToggle
    // }

    // handleSelectedFile() {
         
    //     const delfiles = this.files.filter(x => x.selected)
    //     delfiles.forEach(x =>{
    //         if(x.flowObj){ //파일 업로드 직후
    //             x.flowObj.removeFile(x)
    //         }else{ //이미 저장된 파일목록을 backend 에서 가져온 경우
    //             x.deleteFile()
    //         }
    //     } )
    // }

}
