import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import * as dateUtil from '@appbase/util/date-helper'

@Component({
    components: {},
})

export default class SurveyDetailHeader extends Vue {
    @Prop({ type: Object }) value!: survey.SurveyDetailItem

    descriptionTxt: string = ""


    created() {
        this.convertDescription()
    }

    convertDescription() {
        const des = this.value.description
        this.descriptionTxt = des.replaceAll('\n','<br/>')
    }


    convertDate(date: string) {
        return dateUtil.uGetDateFormat_UTC("yyyy.MM.dd", new Date(date))
    }
}
