import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import api from '@survey/api'

@Component({
    components: {},
    methods: {


    },

})

export default class SurveyStatisticsExamples extends Vue {
    @Prop({ type: Array }) value!: Array<survey.SurveyStatisticsItem>
    @Prop({ type: Number }) totalCount!: number
    @Prop({ type: Boolean }) isAnonymous!: boolean

    isInitAdditionalAnswer: boolean = false
    additionalAnswers: Array<survey.SurveyStatisticsItem> = []

    //카운트가 제일 높은 경우 font blue
    highestCount: number = 0
    additionalTotalCount : number = 0

    @Watch("value")
    onChangedValue() {
      

        if (!this.isInitAdditionalAnswer) {
            this.initAdditionalAnswers()
            this.isInitAdditionalAnswer = true
        }
        
        //이때 제잉ㄹ 카운터가 많은 아이디 추림
        this.highestCount = Math.max(...this.value.map(item => {
            return item.surveyAnswerCount
        }, 0))

        if(this.additionalTotalCount > this.highestCount){
            this.highestCount = this.additionalTotalCount
        }


    }


    initAdditionalAnswers() {

        let count = 0

        this.additionalAnswers = this.value.filter(item => {
            return item.isAdditionalAnswer == true
        })
        count = this.additionalAnswers.length
        this.additionalTotalCount = count

        return count
    }

    //미사용
    poopupDetail(questionId: number) {
        // alert(`상셍보기 팝업 발생 아이디 : ${questionId}`)
        api.survey.getSurveyStatisticsDetailLists(questionId)
            .then(result => {
                this.$emit('modal', result)
            })
    }

    CalculatePercent(answerCount: number) {
        return `${(answerCount / this.totalCount * 100).toFixed(1)}%`
    }

    CalculateAnswerCount() {
        let count = 0

        this.value.forEach(item => {
            count += item.surveyAnswerCount
        })

        return count
    }

    exceptAdditionalAnswer() {
        
        return this.value.filter(item => {
            return item.isAdditionalAnswer == false
        })
    }


    getEssayText() {
        let answerCount = this.CalculateAnswerCount()
        let txt = this.$t('서술형_답변_총').toString()
        txt = txt.replace("{COUNT}", answerCount.toString())
        return txt
    }
}
