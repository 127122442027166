import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapActions } from 'vuex';
import { createEventBus, getDispatchData, removeEventBus } from '@appbase/util/eventBus'
import { openPopup } from '@appbase/util/popup-helper'
import {TWishContainerValueType} from '@orgmap/types/enums'
import modalHelper from '@appbase/util/modal-helper';
import OrgModalView from '@orgmap/views/OrgmapModalView.vue'

export interface TWishContainerOptionItem {
    id: number
    name: string
    height: string
    items? : orgmap.TWishContainerItem[]
}

export interface TWishContainerOptions {
    addableDept: boolean,
    containers: TWishContainerOptionItem[]
}

export interface TWishItemPropsOptions {
    props: string[]
}

const EVENT_ORG_POPUP = '$survey/org/popup'

@Component({
    components: {

    },
    methods: {
        ...mapActions({

        }),
    }
})

export default class SurveyTargetContainer extends Vue {
    @Prop({ default: [], type: Array }) users!: Array<survey.SurveyTargets>
    

    created() {
        createEventBus(window, EVENT_ORG_POPUP, this.handlePopupResult)
    }

    beforeDestroy() {
        removeEventBus(window, EVENT_ORG_POPUP, this.handlePopupResult)
    }

    getDisplayName(data : survey.SurveyTargets){

        if(data.isDepartment){
            return (data as any).name
        }else{
            return `${(data as any).name} (${(data as any).deptName})`
        }
    }


    remove(personCode: string) {

        this.$emit("delete",personCode)

        // this.users = this.users.filter(item => {
        //     return item.personCode != personCode
        // })
    }


    popupOrg() {

        const options: TWishContainerOptions = {
            addableDept: true,
            containers: [
                {
                    id: 0,
                    name: this.$t('대상').toString(),
                    height: '1',
                    items: this.users.map((item:any) =>{
                        
                        return{
                            text: "",
                            value: item.id,
                            valueType : item.isDepartment ? TWishContainerValueType.deptCode : TWishContainerValueType.personCode,
                            selected: false
                        } as orgmap.TWishContainerItem

                    })
                }
            ]
        }
        const props: TWishItemPropsOptions = {
            props: ['id', 'name', 'personCode', 'deptName','deptCode'],
        }


        if(true){
            modalHelper.show({
                component: OrgModalView, force: true, value: {
                    options: options,
                    props: props,
                    event: EVENT_ORG_POPUP
                }
            });
        }else{
  
            openPopup(
                'surveyTarget',
                '/orgmap',
                { event: EVENT_ORG_POPUP, option: options, props },
                {
                    width: 1024,
                    height: 700,
                }
            )
        }

     
    }

    handlePopupResult() {
        
        const result = getDispatchData(EVENT_ORG_POPUP) || {}
        
        //여기서 바로 올려야되네
      
        //name
        //deptName
        //personCode
        if (result[0].items.length > 0) {
           
            // this.users = result[0].items
            let containerData = result[0].items.map((data : any) => {
                if (data.personCode && data.personCode != "") {
                    return {
                        ...data,
                        identityCode: data.personCode,
                        isDepartment: false,
                        name : data.text
                        
                    }
                } else {
                    return {
                        ...data,
                        identityCode: data.id,
                        isDepartment: true,
                        deptName : data.deptName,
                        name: data.deptName
                    }
                }
            })
 
            this.$emit('update', containerData)

        }

    }


     

}
