import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
 



@Component({
    components: {},
})

export default class SurveyStatisticsUserSearchList extends Vue {
    @Prop({ type: Array, default:[] }) value!: Array<survey.SurveyCompletionItem>
    @Prop({ type: Number, default:0 }) totalCount!: number
    @Prop({ type: Number, default:0 }) selectedTrNum!: number
    
    keyword : string = ""
}
