import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex';
import * as types from '@survey/store/types'
import * as dateUtil from '@appbase/util/date-helper'
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue'

@Component({
    components: {
        PageNavigationBar
    },
    computed: {
        ...mapGetters({
            list: types.SURVEY_LIST,
        }),
    }
})

export default class SurveyGrid extends Vue {

    @Prop({ default:5, type: Number }) pageRange!: number  
    @Prop({ default:20, type: Number }) rowCount!: number  

    list!: survey.SurveyListResponse
    selectedPageNumber: number = 1


  

    convertDate(date: string) {
        return dateUtil.uGetDateFormat_UTC("yyyy.MM.dd", new Date(date))
    }

    convertStatus(data: survey.SurveyListItem, isClass: boolean = false) {
        var returnString = ""
        switch(data.surveyStatus){
            case 1:
                returnString = isClass ? "badge-lightgray" : this.$t('게시대기').toString()
                break
            case 2:
                let spanDays = this.getSpanDays( new Date(data.endDate))

                if(spanDays > -1 && spanDays < 4){
                    returnString = isClass ? `badge-yellow` : `D-${spanDays == 0 ? 'day' : spanDays}`
                }else{ //진행중일 때 종료3일전부터는 d-3 으로 표시
                    returnString = isClass ? "badge-green" : this.$t('진행중').toString()
                }
                break
            case 3:
                returnString = isClass ? "badge-lightgray" : this.$t('종료').toString()
                break
        }
         
        return returnString
    }

    getSpanDays(endDate : Date){
        endDate.setHours(23,59,59,999); //endOfDays
        const now = new Date()

        var diff = endDate.getTime() - now.getTime();
        let days = Math.floor(diff / (1000 * 60 * 60 * 24));

        return days
    }


    goRead(surveyId : number){
         

        this.$router.push({
            name : "SurveyRead",
            query: { 
                MENUID : this.$route.query.MENUID,
                surveyId: surveyId.toString() 
            }
        })
    }

    pageMove(pageNum : number){
        this.selectedPageNumber = pageNum
        
        this.$emit('search',pageNum)
    }

}
