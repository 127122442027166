import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import api from '@survey/api'


@Component({
    components: {},
})

export default class SurveyReadQuestion extends Vue {
    @Prop({ type: Object }) value!: survey.SurveyQuestionDetail
    @Prop({ type: Boolean, default: false }) isStatistics!: boolean
    // 

    isMultipleAnswer : boolean = false


    created() {
        this.value.questionInfo.questionText
        this.value.questionInfo.questionOrders

        //복수선택 질문인지 체크 한다
        //현재는 type 2외엔 없음
        if(this.value.questionInfo.questionType == 1) this.isMultipleAnswer = true
    }

    poopupDetail(questionId: number) {
        // alert(`상셍보기 팝업 발생 아이디 : ${questionId}`)
        api.survey.getSurveyStatisticsDetailLists(questionId)
            .then(result => {
                this.$emit('modal', result)
            })
    }

    setTitle(){
        return `${this.value.questionInfo.questionOrders.toString().length == 1 ? '0':''}${this.value.questionInfo.questionOrders}. ${this.isMultipleAnswer ? '(복수선택)': '' } ${this.value.questionInfo.questionText} `
    }
}
