import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import   SurveySubHeader  from '@survey/components/Common/SurveySubHeader.vue'
import { i18n } from '@appbase/util/i18n-setup'

@Component({
  components: { SurveySubHeader },
})
export default class SurveyCommonWrap extends Vue {

  @Prop({ default: i18n.t('설문조사').toString(), type: String }) headerText!: string  
  @Prop({ default: false, type: Boolean }) enableHeaderIcon!: Boolean  
 
}
